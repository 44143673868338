






































import { Polygon } from '@/models/entities/Polygon';
import { ApiFacade } from '@/services/ApiFacade';
import { AppStore } from '@/store/types/AppStore';
import {
  Component, Inject, InjectReactive, Vue,
} from 'vue-property-decorator';
import { CellStatus } from '@/types/Statuses';
import { WampService } from '@/services/WampService';
import PolygonCellEditable from '@/views/polygons/components/PolygonCellEditable.vue';

@Component({
  components: { PolygonCellEditable },
})
export default class PolygonPayloadView extends Vue {
  @Inject() readonly apiFacade!: ApiFacade;
  @Inject() appStore!: AppStore;
  @Inject() wampService!: WampService;
  @InjectReactive() polygon!: Polygon | null;
  private isLoading = false;

  get activeCells() {
    return this.polygon?.cells.filter((c) => c.status === CellStatus.active) || [];
  }

  get isAdmin() {
    return this.appStore.getters.profile?.isAdmin;
  }

  get payloadState() {
    return this.appStore.getters.payloadState;
  }

  powerOffAll() {
    this.activeCells.forEach((cell) => {
      const cellEditableCmp = this.$refs[`cell_edit_${cell.cellId}`] as Element[] & { clear: Function }[];
      cellEditableCmp[0].clear();
    });
  }
}
